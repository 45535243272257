import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import VueAxios from "vue-axios";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueCarousel from "vue-carousel";
import VueSweetalert2 from "vue-sweetalert2";
import firebase from "firebase";
// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";

Vue.use(VueSweetalert2);
require("../src/assets/scss/style.scss");
// app.js
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import VueAwesomeSwiper from "vue-awesome-swiper";

// import style (>= Swiper 6.x)
// import "swiper/swiper-bundle.css";

// import style (<= Swiper 5.x)
import "swiper/css/swiper.css";

Vue.use(VueAwesomeSwiper /* { default options with global component } */);

// import "./style/custom.css";
Vue.use(VuePlyr);
Vue.use(VueAxios, axios);
Vue.use(VueCarousel);

// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.config.productionTip = false;
import VueCoreVideoPlayer from "vue-core-video-player";
import vuetify from "./plugins/vuetify";
Vue.use(VueCoreVideoPlayer, {
  lang: "en",
});
const firebaseConfig = {
  apiKey: "AIzaSyBfEQuqXWwAsBlpV_PKC7b3oZTmlY8kO8Q",
  authDomain: "e-learning-305109.firebaseapp.com",
  projectId: "e-learning-305109",
  storageBucket: "e-learning-305109.appspot.com",
  messagingSenderId: "226372908643",
  appId: "1:226372908643:web:291c8733ccf8b1b69e6a56",
  measurementId: "G-174TEYPS8M",
};
firebase.initializeApp(firebaseConfig);
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
