import Courses from "./api/coursesAPI";
import auth from "@/service/api/AuthAPI";
import Category from "@/service/api/CategoryAPI";
import Lessons from "@/service/api/LessonsAPI";
import Teachers from "@/service/api/TeacherAPI";
import Question from "@/service/api/QuestionAPI";
import Ministry from "@/service/api/MinistryAPI";
export default {
  Courses,
  auth,
  Category,
  Lessons,
  Teachers,
  Question,
  Ministry,
};
