// // local
// export const domain = "http://127.0.0.1:8000/api/";
// export const imageDomain = "http://127.0.0.1:8000/storage/";

// server test
// export const domain = "https://acaapi.ksta.co/api/";
// export const imageDomain = "https://acaapi.ksta.co/storage/";

// server test vc
export const domain = "https://vcapi.ksta.co/api/";
export const imageDomain = "https://vcapi.ksta.co/storage/";

// Server Prod
// export const domain = "https://elearningapi.ordpmk.com/api/";
// export const imageDomain = "https://elearningapi.ordpmk.com/storage/";
