import Service from "../../service/index";

const Ministry = {
  name: "Ministry",
  stateFactory: true,
  namespaced: true,
  state: {
    ministry: [],
    unitMinistry: [],
    unit: [],
    department: [],
  },
  mutations: {
    SET_MINISTRY: (state, data) => {
      state.ministry = data;
    },
    SET_UNIT_MINISTRY: (state, data) => {
      state.unitMinistry = data;
    },
    SET_UNIT: (state, data) => {
      state.unit = data;
    },
    SET_DEPARTMENT: (state, data) => {
      state.department = data;
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    getMinistry({ commit }) {
      return Service.Ministry.onGetMinistry().then((response) => {
        if (response.code === 200) {
          let response_data = response.result;
          commit("SET_MINISTRY", response_data);
          return response;
        }
      });
    },
    getUnitMinistry({ commit }, ministry) {
      return Service.Ministry.onGetUnitMinistry(ministry).then((response) => {
        if (response.code === 200) {
          let response_data = response.result;
          commit("SET_UNIT_MINISTRY", response_data);
          return response;
        }
      });
    },
    getUnit({ commit }, unitMinistry) {
      return Service.Ministry.onGetUnit(unitMinistry).then((response) => {
        if (response.code === 200) {
          let response_data = response.result;
          commit("SET_UNIT", response_data);
          return response;
        }
      });
    },
    getDepartment({ commit }, unit) {
      return Service.Ministry.onGetDepartment(unit).then((response) => {
        if (response.code === 200) {
          let response_data = response.result;
          commit("SET_DEPARTMENT", response_data);
          return response;
        }
      });
    },
  },
};
export default Ministry;
