import Service from "../service";

const Teachers = {
  onTeacherList(search) {
    return Service.get(`/teachers`, {
      params: { search },
    });
  },
  onGetTeacher(id) {
    return Service.get(`/teachers/${id}`);
  },
};

export default Teachers;
