<template>
  <div class="home">
    <Loading :loading="isLoading"></Loading>
    <topNav v-if="menus.find((menu) => menu.name == 'topNav')" />
    <b-row>
      <b-col lg="2">
        <!--        <sideMenuMobile />-->
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col class="text-center" cols="12">
        <swiper
          ref="carousel"
          @click-slide="handlerSlide"
          :options="swiperOptions"
        >
          <swiper-slide
            v-for="banner in banners"
            class="pointer"
            :key="banner.id"
          >
            <img
              class="mx-auto"
              :src="banner.image_url"
              style="max-width: 100vw; max-height: 200px"
              alt=""
            />
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </b-col>
    </b-row>

    <!-- <adsCouse /> -->
    <b-row class="justify-content-md-center">
      <b-col v-if="menus.find((menu) => menu.name == 'sideMenu')" lg="2">
        <sideMenu />
      </b-col>
      <b-col
        lg="10"
        :class="`${
          menus.find((menu) => menu.name == 'allTeacher') ? 'mt-5' : ''
        } px-5`"
      >
        <allTeacher
          v-if="menus.find((menu) => menu.name == 'allTeacher')"
          @search="teacherSearchHandler"
        />
        <b-row
          :class="`${
            menus.find((menu) => menu.name == 'allTeacher') ? 'mt-5' : ''
          } `"
          align-content="between"
        >
          <b-col class="my-auto text-center text-md-left" cols="12" md="3">
            <span class="font-weight-bold" style="font-size: 24px"
              >คอร์สเรียน</span
            >
          </b-col>
          <b-col class="my-auto" cols="12" md="6">
            <Search
              placeholder="ค้นหาคอร์สเรียน..."
              @search="searchCourseHandler"
            />
          </b-col>
          <b-col class="my-auto text-center text-md-right" cols="12" md="3">
            <b-button-group>
              <b-button
                @click="reorderCourse('top')"
                :variant="orderType === 'top' ? 'warning' : ''"
                >Top View</b-button
              >
              <b-button
                @click="reorderCourse('newest')"
                :variant="orderType === 'newest' ? 'warning' : ''"
                >Newest</b-button
              >
            </b-button-group>
          </b-col>
        </b-row>
        <b-row
          cols="1"
          cols-sm="2"
          cols-md="3"
          cols-lg="3"
          cols-xl="4"
          align-v="stretch"
        >
          <b-col
            v-for="item in allCourses"
            :key="item.id"
            class="subject-card my-2"
            style="max-height: 300px"
          >
            <CourseCard
              :id="item.id"
              :header="item.name"
              :content="item.description"
              :image="item.banner_image ? item.banner_image : noImage"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- <b-row>
      <b-col lg="10" offset-lg="2">
        <div class="d-flex flex-wrap">
          <div
            v-for="item in allCourses"
            :key="item.id"
            class="subject-card mb-5"
          >
            <CourseCard
              :id="item.id"
              :header="item.name"
              :content="item.description"
              :image="item.banner_image ? item.banner_image : noImage"
            />
          </div>
        </div>
      </b-col>
    </b-row> -->
  </div>
</template>

<script>
// @ is an alias to /src
import bg from "../assets/image/bg.png";
import noImage from "../assets/image/mock/no-image.png";
import CourseCard from "@/components/CourseCard.vue";
import topNav from "@/components/topNav.vue";
// import sideMenuMobile from "@/components/sideMenuMobile.vue";
import sideMenu from "@/components/sideMenu.vue";
import Loading from "@/components/Loading.vue";
import allTeacher from "@/components/allTeacher.vue";
import { mapState } from "vuex";
import Search from "@/components/search";

export default {
  name: "Home",
  data() {
    return {
      data: null,
      bg: bg,
      dataCreator: null,
      categoryId: null,
      noImage: noImage,
      isLoading: false,
      orderType: "top",
      banners: [],
      swiperOptions: {
        loop: true,
        initialSlide: 0,
        autoplay: {
          delay: 1500,
          disableOnInteraction: true,
        },
        speed: 800,
        // If we need pagination
        pagination: {
          el: ".swiper-pagination",
        },

        // Navigation arrows
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },

        // And if we need scrollbar
        scrollbar: {
          el: ".swiper-scrollbar",
        },
      },
    };
  },
  computed: {
    ...mapState({
      allCourses: (state) => {
        return state.Courses.allCourses;
      },
      menus: (state) => {
        return state.Courses.menus;
      },
    }),
  },
  async mounted() {
    this.isLoading = true;
    await this.getAllCourses();
    await this.getBanners();
    this.isLoading = false;
  },
  components: {
    topNav,
    CourseCard,
    Loading,
    // sideMenuMobile,
    sideMenu,
    allTeacher,
    Search,
  },

  methods: {
    async getBanners() {
      const banners = await this.$store.dispatch("Courses/onGetBanners");
      this.banners = banners;
    },
    async teacherSearchHandler(text) {
      this.isLoading = true;
      await this.$store.dispatch("Teachers/TeachersList", {
        search: text,
      });
      this.isLoading = false;
    },
    async searchCourseHandler(text) {
      this.isLoading = true;
      await this.$store.dispatch("Courses/getAllCourse", {
        order: this.orderType,
        keyword: text,
      });
      this.isLoading = false;
    },
    async reorderCourse(type) {
      this.orderType = type;
      this.isLoading = true;
      await this.getAllCourses();
      this.isLoading = false;
    },
    async getAllCourses() {
      await this.$store.dispatch("Courses/getAllCourse", {
        order: this.orderType,
      });
    },
  },
};
</script>
<style>
.home {
  height: auto;
  min-height: 100vh;
}
</style>
